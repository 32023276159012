import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import CustomNavLink from './CustomNavLink'
import loaderSpinner from '../images/spinner.png'
import './CtaShuffle.scss'

/**
* CtaShuffle component
* @param {String} param.value - value(text) for button or link 
* @param {String} param.type - either 'button' or 'link', depending on element you want to use
* @param {String} param.path - path for a link if type prop is link
* @param {Boolean} param.showLoader 
* @param {Boolean} param.showShuffleElement
* @param {Boolean} param.isPageLoadingAnimation - optional(default value false)
*
* @returns {JSX Element}
*/
export default function CtaShuffle({ value, type, path, showLoader, showShuffleElement, isPageLoadingAnimation = false }) {

  const [start, setStart] = useState(false)
  const [characterShuffle, setCharacterShuffle] = useState(value)
  
  const onEnter = () => setStart(true)
  const onLeave = () => {
    setStart(false)
    setCharacterShuffle(value)
  }
  
  useEffect(() => {
    if(isPageLoadingAnimation) {
      setStart(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    let timer = null;

    if(start) {
      // creating array and spliting value string into an array of characters 
      const originalText = value.split('')
      let counter = 0;

      // random shuffling characters in array
      const randomShuffle = (characters) => {
        // temporary variable to store characters from array while random shuffling
        let tempCharacters;

        for (let i = characters.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          tempCharacters = characters[i];
          characters[i] = characters[j];
          characters[j] = tempCharacters;
        }

        return characters;
      }

      const repeatShuffle = (times, index) => {
        isPageLoadingAnimation && counter++

        if(index === times) {
          setCharacterShuffle(value);
          
          return;
        }
        
        timer = setTimeout(() => {
          let randomCharacters = randomShuffle(originalText)
          
          // adding some other charachters to shuffle array
          index === 0 && randomCharacters.push('!', '#', '$', '&', '1', '2', '3', '4', '5', '6');

          for(let i=0; i<index; i++) {
            randomCharacters[i] = value[i];
          }
          randomCharacters = randomCharacters.join('');
          // removing randomly from the end, the same number of characters as added(!, @...), so the word won't have more characters while shuffling
          randomCharacters = randomCharacters.substring(0, randomCharacters.length - (randomCharacters.length - value.length));

          setCharacterShuffle(randomCharacters);
          if(isPageLoadingAnimation) {
            counter > 30 && index++
          } else {
            index++
          }
          repeatShuffle(times, index);
        }, 50)
      }
      repeatShuffle(originalText.length, 0);
    }
    return () => clearTimeout(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [start])


  return (
    <>
      {
        (() => {
          switch (type) {
            case 'button':
              return (
                <button
                  className="cta-shuffle__btn"
                  onMouseEnter={onEnter}
                  onMouseLeave={onLeave}
                >
                  {!showLoader ? characterShuffle : <img src={loaderSpinner} alt="Loading spinner" />}
                </button>
              )
            case 'link':
              return (
                <CustomNavLink 
                  path={path}
                  classes="cta-shuffle__link"
                  onMouseEnter={onEnter}
                  onMouseLeave={onLeave}
                >
                  {characterShuffle}
                </CustomNavLink>
              )
            case 'loader':
              return (
                <span
                  className={`cta-shuffle__loader ${showShuffleElement ? 'cta-shuffle__loader--fade' : ''}`}
                >
                  {characterShuffle}
                </span>
              )
            default:
              return null
          }
        })()
      }
    </>
  )
}

CtaShuffle.propTypes = {
  value: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  path: PropTypes.string,
  showLoader: PropTypes.bool,
  showShuffleElement: PropTypes.bool,
}