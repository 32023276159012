import { useEffect } from 'react';

// The setVhProperty function calculates the --vh custom property and sets it on the root element
const setVhProperty = () => {
  // Calculate 1% of the viewport height (including browser UI elements)
  let vh = window.innerHeight * 0.01;
  // Set the --vh custom property on the root element with the calculated value
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

const useViewportHeight = (debounceDelay = 80) => {
  useEffect(() => {
    // Set the initial --vh property value when the component mounts
    setVhProperty();

    let timeoutId;

    // Define the resizeListener function, which will be called on the 'resize' event
    const resizeListener = () => {
      // Clear the existing timeout, if any
      clearTimeout(timeoutId);
      // Set a new timeout to call setVhProperty after a specified wait time (default is 100ms)
      timeoutId = setTimeout(() => setVhProperty(), debounceDelay);
    };

    // Add the resizeListener function as an event listener for the 'resize' event
    window.addEventListener('resize', resizeListener);

    // Clean up the effect by removing the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', resizeListener);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useViewportHeight;