import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Link, navigate } from "gatsby"
import { useLocation } from "@reach/router"
import { PageTransitionContext } from '../context/pageTransitionContext'

/**
 * MenuLink component
 * 
 * @param {Any} children
 * @param {String} path
 * @param {Function} onMouseEnter
 * @param {Function} onMouseLeave
 * @param {Object} activeStyle
 * @param {String} classes
 * 
 * @returns {JSX Element}
 */
export default function CustomNavLink({ path, children, onMouseEnter, onMouseLeave, activeStyle, classes  }) {

  const location = useLocation()
  const {setPagePosition} = useContext(PageTransitionContext)

  const redirect = e => {
    e.preventDefault()
    
    const bodyClasses = document.body.classList
    const curtain = document.getElementById('curtain-animation');
    
    if(location.pathname !== path) {
      setPagePosition(-100)
      curtain.classList.add('curtain--show')
      
      let timer = setTimeout(() => {
        navigate(path, {state: { shouldAnimate: true }})

        if(bodyClasses.contains('no-scroll')) {
          bodyClasses.remove('no-scroll')
        }
      }, 1000)

      return () => {
        clearTimeout(timer)
      }
    }  
  }

  return (
    <Link 
    to={path} 
    className={classes} 
    activeStyle={activeStyle} 
    onMouseLeave={onMouseLeave} 
    onMouseEnter={onMouseEnter} 
    onClick={redirect}>
      {children}
    </Link>
  )
}

CustomNavLink.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.string.isRequired,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  activeStyle: PropTypes.object,
  classes: PropTypes.string
}