import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types';

import FaceIcon from '../svg-images/facebook.svg'
import InstagramIcon from '../svg-images/instagram.svg'
import LinkedinIcon from '../svg-images/linkedin.svg'

export default function SocialsComponent({classes, isLightTheme}) {

  const data = useStaticQuery(graphql`{
    allWp {
      nodes {
        acfOptionsOptionsPage {
          optionsPage {
            socialIconsRepeater {
              name
              url
            }
          }
        }
      }
    }
  }`)
  
  const {allWp: {nodes}} = data;
  const {acfOptionsOptionsPage: { optionsPage: {socialIconsRepeater}}} = nodes[0];

  return (
    <div className={`${classes}__socials`} >
      <div className={`${classes}__socials-wrapper`} >
      {
        socialIconsRepeater.map((item, index) => {

          return (
              <a key={index} href={item.url} target="__blank" rel="noreferrer" className={`${classes}__socials-links ${isLightTheme ? `${classes}__socials-links--light` : ''}`} > 
                {
                  (() => {
                    switch(item.name) {
                      case 'facebook':
                        return <FaceIcon />
                      case 'instagram':
                        return <InstagramIcon />
                      case 'linkedin':
                        return <LinkedinIcon />
                      default:
                        return null
                    }
                  })()
                }
              </a>
            )
          })
        }
      </div>
    </div>
  )
}

SocialsComponent.propTypes = {
  classes: PropTypes.string.isRequired,
  isLightTheme: PropTypes.bool
}