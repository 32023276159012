import { useRef } from 'react'
import { useScrollPosition } from './useScrollPosition'
import { applyCssProperties } from '../utils/utils'

/**
 * useSmoothScroll
 * 
 * @param {Ref} param.element - element
 * @param {Number} param.ease - controlls smoothness effect
 * @param {Boolean} param.isInView - intersection observer return object property(isIntersecting). By default is true, for elements that are always in viewport(scroll and scroll bar)
 * @param {Func} param.calcElementMovementOnScroll - calculates speed and direction of how element is moving
 */
export default function useSmoothScroll({element, ease, isInView = true, calcElementMovementOnScroll}) {
  
  const smoothScrollPos = useRef(0)

  const animationFrameId = useRef(null)

  const cancelAnimationFrame = window.cancelAnimationFrame || window.mozCancelAnimationFrame

  /**
   * updateElementPositionOnScroll
   * 
   * Updates element position on scroll using smooth effect. For parralx effect also.
   * 
   * @param {Ref} param.element 
   * @param {Number} param.ease - controlls smoothness effect
   * @param {Ref} param.smoothScrollPos - ref to keep value of smooth scroll position(using ref so we keep the value on rerender)
   * @param {Number} param.currPos - current position of the scroll
   */
  const updateElementPositionOnScroll = (element, ease, smoothScrollPos, currPos) => {

    if(smoothScrollPos.current === 0)
      // this runs on first execution to sync smooth scroll with current scroll
      smoothScrollPos.current = currPos
    else
      // calculating smoothScrollPos based on a current position of scroll and multiplying with small constant(0.08) to get delay
      smoothScrollPos.current += (currPos - smoothScrollPos.current) * ease

    applyCssProperties(element, 'transform', `translate3d(0px, ${calcElementMovementOnScroll(smoothScrollPos.current)}px, 0px)`)

    cancelAnimationFrame(animationFrameId.current)
    
    // if smooth scroll pos isn't equal to current scroll pos call again, until they are
    if(Math.abs(currPos - smoothScrollPos.current) > 0.05)
      animationFrameId.current = requestAnimationFrame(() => updateElementPositionOnScroll(element, ease, smoothScrollPos, currPos))
  }

  
  useScrollPosition(({currPos}) => {
    cancelAnimationFrame(animationFrameId.current)
    if(isInView)
      animationFrameId.current = requestAnimationFrame(() => updateElementPositionOnScroll(element, ease, smoothScrollPos, Math.abs(currPos.y)))
  })
}