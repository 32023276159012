import React from 'react'
import { useStaticQuery, graphql } from "gatsby"

import './EmailInfo.scss'

/**
 * EmailInfo component
 * 
 */
export default function EmailInfo() {

  const data = useStaticQuery(graphql`
    query EmailInfo {
      wp {
        acfOptionsOptionsPage {
          optionsPage {
            emailInfo {
              emailLabel
              email
            }
          }
        }
      }
    }
  `)

  const { wp: { acfOptionsOptionsPage: { optionsPage: { emailInfo } }}} = data

  return (
    <>
      {
        emailInfo.email && <div className="email-info">
          {emailInfo.emailLabel && <p className="email-info__label">{emailInfo.emailLabel}</p>}
          <a href={`mailto:${emailInfo.email}`} className="email-info__text">{emailInfo.email}</a>
        </div>
      }
    </>
  )
}
