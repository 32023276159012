import React, {forwardRef} from 'react'
import PropTypes from 'prop-types'

import useSmoothScroll from '../hooks/useSmoothScroll'
import {useCurrentHeight} from '../hooks/useResize'
import {useCurrentWidth} from '../hooks/useResize'

import {getMousePosition} from '../utils/utils'

import './CustomScrollBar.scss'

/**
 * CustomScrollBar
 * 
 * @param {Number} param.contentHeight
 * @param {Number} param.footerHeight
 * @param {Function} param.setClickOffsetInBar
 * @param {Function} param.setIsDragging
 * @param {Number} param.ease
 * 
 * @returns {JSX}
 */
const CustomScrollBar = forwardRef(({contentHeight, footerHeight, setClickOffsetInBar, setIsDragging, ease}, scrollBarRef) => {

  const windowHeight = useCurrentHeight()
  const windowWidth = useCurrentWidth()

  /**
   * calcElementMovementOnScroll
   * 
   * @param {Number} smoothScrollPos
   * @returns {Number} movement of element on scroll
   */
  const calcElementMovementOnScroll = (smoothScrollPos) => {
    const pageHeight = contentHeight - (windowHeight - footerHeight)
    const maxScrollBarMove = windowHeight - scrollBarRef.current?.clientHeight

    return maxScrollBarMove * smoothScrollPos / pageHeight
  }

  /**
   * onMouseDown
   * 
   * This function sets position of click inside element, and also sets dragging state to true
   * 
   * @param {Event} e 
   */
  const onMouseDown = (e) => {
    const offsetOnClick = getMousePosition(e, scrollBarRef)
    setClickOffsetInBar(offsetOnClick.y)
    setIsDragging(true)
  }

  useSmoothScroll({element: scrollBarRef, ease: ease, calcElementMovementOnScroll: calcElementMovementOnScroll})

  return (
    <>
      {
        windowWidth > 1023 &&
        <div className="scroll__container">
          <div 
            className="scroll__bar" 
            ref={scrollBarRef}
            style={{height: `${((500 * windowHeight) / contentHeight)}px`}}
            onMouseDown={(e) => onMouseDown(e)}
            role="presentation"
          >
            <div className="scroll__bar-handle"></div>
          </div>
        </div>
      }
    </>
  )
})

export default CustomScrollBar

CustomScrollBar.propTypes = {
  contentHeight: PropTypes.number,
  footerHeight: PropTypes.number,
  setClickOffsetInBar: PropTypes.func.isRequired,
  setIsDragging: PropTypes.func.isRequired,
  ease: PropTypes.number.isRequired
}