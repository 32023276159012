import React, {useRef, useEffect} from 'react'
import PropTypes from 'prop-types'

import FooterMenuItems from './FooterMenuItems'
import FooterCopyright from './FooterCopyright'
import EmailInfo from './EmailInfo'

import { useCurrentWidth } from '../hooks/useResize'
import smoothscroll from 'smoothscroll-polyfill';

import Logo from '../svg-images/breyta-logo.svg'

import './FooterMenuItems.scss'
import './Footer.scss'

/**
* Footer component
* @param {Array} param.footerMenu
* @param {Object} param.optionsPage
* @param {Function} param.footerCallback
*
* @returns {JSX Element}
*/
export default function Footer({footerMenu, copyrightField, footerCallback}) {

  const {menuItems: {nodes}} = footerMenu[0]

  const footerHeight = useRef(null);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  useEffect(() => {
    footerHeight.current && footerCallback(footerHeight.current.clientHeight)
    smoothscroll.polyfill()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    footerHeight.current && footerCallback(footerHeight.current.clientHeight)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useCurrentWidth()])

  return (
    <footer className="footer" ref={footerHeight}>
      <Logo className="footer__logo" />
      <div className="footer-wrapper">
        <FooterMenuItems parentItems={nodes}/>
        <div className="footer__email">
          <EmailInfo />
        </div>
      </div>
      <span className="footer__back"
        role="button" 
        tabIndex="0" 
        onClick={scrollToTop}
        onKeyPress={scrollToTop}
      >
        BACK TO TOP
      </span>
      <FooterCopyright copyright={copyrightField}/>
    </footer>
  )
} 

Footer.propTypes = {
  footerMenu: PropTypes.array.isRequired,
  copyrightField: PropTypes.string.isRequired
}