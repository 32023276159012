import React from 'react'
import PropTypes from 'prop-types'

import { useCurrentWidth } from '../hooks/useResize'
import CtaShuffle from './CtaShuffle'
import CustomNavLink from './CustomNavLink'
import './Menu.scss'

/**
 * Menu component
 * 
 * @param {Array} mainMenu
 * @param {Boolean} isOpen
 * 
 * @returns {JSX Element}
 */
export default function Menu({ mainMenu, isOpen }) {

  const windowWidth = useCurrentWidth()
  const { menuItems: {nodes}} = mainMenu[0]
  const menuItemsData = nodes
  
  const isDesktopSize = windowWidth >= 1024

  return (
    <nav className="nav">
      <ul 
        className="nav__wrapper"
        style={{ opacity: `${isOpen ? '1' : '0'}`}}
      >
        {
          menuItemsData.map((item, index) => {
            return (
              <li className="nav__item" key={index}>
                {
                  isDesktopSize ? <CtaShuffle type="link" path={item.path} value={item.label} /> : <CustomNavLink path={item.path} activeStyle={{ opacity: 1 }}>{item.label}</CustomNavLink>
                }
              </li>
            )
          })
        }
      </ul>
    </nav>
  )
}
    
Menu.propTypes = {
  mainMenu: PropTypes.array,
  isOpen: PropTypes.bool
}
