import React from 'react'
import SocialsComponent from './SocialsComponent'
import PropTypes from 'prop-types'

/**
* FooterCopyright component
* @param {String} param.copyright
*
* @returns {JSX Element}
*/
export default function FooterCopyright({copyright}) {

  return (
    <div className="footer__copyright">
      <div className="footer__copyright-text">
        <p>{copyright}</p>
        <p>{new Date().getFullYear()}</p>
      </div>
      <SocialsComponent classes="footer"/>
    </div>
  )
}

FooterCopyright.propTypes = {
  copyright: PropTypes.string.isRequired
}