import React, {useRef} from 'react'
import PropTypes from 'prop-types'

import useResizeObserver from '../hooks/useResizeObserver'

import useSmoothScroll from '../hooks/useSmoothScroll'


/**
 * CustomScroll
 * 
 * @param {Nodes} param.children
 * @param {Function} param.contentHeight
 * @param {Number} param.marginBottomValue
 * 
 * @returns {JSX}
 */
export default function CustomScroll({children, marginBottomValue = 0, setContentHeight, pagePosition, ease}) {

  const content = useRef(null);

  const updateOnElementChange = () => content.current && setContentHeight(content.current.clientHeight)

  /**
   * calcElementMovementOnScroll
   * 
   * @param {Number} smoothScrollPos
   * @returns {Number} movement of element on scroll
   */
  const calcElementMovementOnScroll = (smoothScrollPos) => -smoothScrollPos

  // watching if element changed and setting height to parrent
  useResizeObserver({callback: updateOnElementChange, element: content})

  // upddating content position
  useSmoothScroll({ element: content, ease: ease, calcElementMovementOnScroll: calcElementMovementOnScroll})

  return (
    <div 
      className="scroll-container" 
      style={{
        position: 'fixed',
        width: '100%',
        height: `calc(100% - ${marginBottomValue}px)`,
        transform: `${pagePosition ? `translateY(${pagePosition}px)` : 'unset' }`,
        transition: `transform .7s`,
        willChange: 'transform'
      }}
    >
      <div className="content-container" ref={content}>
          {children}
      </div>
    </div>
  )
}

CustomScroll.propTypes = {
  children: PropTypes.node.isRequired,
  marginBottomValue: PropTypes.number,
  setContentHeight: PropTypes.func.isRequired,
  pagePosition: PropTypes.number.isRequired,
  ease: PropTypes.number.isRequired
}