import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'

import CtaShuffle from './CtaShuffle'

/**
 * SiteLoadingAnimation component
 * 
 * @param {Boolean} isAnimated 
 * 
 * @returns {JSX Element}
 */
export default function SiteLoadingAnimation({ isAnimated }) {

  const [showShuffleElement, setShowShuffleElement] = useState(false)

  useEffect(() => {
    
    // Showing shuffle element
    let timer = setTimeout(() => {
      setShowShuffleElement(true)
    }, 800)
    
    return () => {
      clearTimeout(timer)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={`page-loading-animation ${isAnimated ? 'page-loading-animation--move' : ''}`}>
      {showShuffleElement && 
        <CtaShuffle 
          type="loader" 
          value="Breyta" 
          isPageLoadingAnimation={true}
          showShuffleElement={showShuffleElement}
        />
      }
    </div>
  )
}

SiteLoadingAnimation.propTypes = {
  isAnimated: PropTypes.bool
}