import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Menu from './Menu'
import SocialsComponent from './SocialsComponent'
import EmailInfo from './EmailInfo.js'
import CustomNavLink from './CustomNavLink'
import { useCurrentWidth } from '../hooks/useResize'
import './Header.scss'

/**
 * Header component
 * 
 * @param {Array} mainMenu
 * 
 * @returns {JSX Element}
 */
export default function Header({ mainMenu }) {

  const windowWidth = useCurrentWidth();
  const [isOpen, setIsOpen] = useState(false)
  const [removeMixBlendMode, setRemoveMixBlendMode] = useState(false)

  /**
   * Toggles nav menu
   */
  const toggleMenu = () => {
    if (!isOpen) {
      document.body.classList.add('no-scroll')
    } else {
      document.body.classList.remove('no-scroll')
    }
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    let timer = null
    // Delay mix-blend-mode: unset value when we close menu
    if (!isOpen) {
      timer = setTimeout(() => {
        setRemoveMixBlendMode(isOpen)
      }, 300);
    } else {
      setRemoveMixBlendMode(isOpen)
    }

    return () => clearTimeout(timer)
  }, [isOpen])

  useEffect(() => {
    if (isOpen && windowWidth >= 1024) {
      document.body.classList.remove('no-scroll')
      setIsOpen(false)
    }
  }, [isOpen, windowWidth])

  return (
    <header
      className={`header ${isOpen ? 'header--nav-opened' : ''}`}
      style={{ mixBlendMode: `${removeMixBlendMode ? 'unset' : 'difference'}` }}
    >
      <div className={`header__logo ${isOpen ? 'header__logo--nav-opened' : ''}`}>
        <CustomNavLink path='/'>
          <svg xmlns="http://www.w3.org/2000/svg" width="78" height="15" viewBox="0 0 78 15">
            <g fill="none" fillRule="evenodd">
              <g fill="#333">
                <g>
                  <path d="M4.41.182c.957-.026 1.883.336 2.57 1.003.68.62 1.06 1.504 1.044 2.424v1.212c-.009.728-.258 1.431-.71 2.002l-.25.275c.108.078.206.17.292.271.213.24.376.519.48.821.21.437.317.915.314 1.4v1.567c.028 1-.385 1.963-1.128 2.633-.726.676-1.685 1.043-2.676 1.025H0V.182h4.41zm16.447 1.003c.67.669 1.032 1.584 1.003 2.53v2.611c-.01 1.208-.625 2.33-1.638 2.989l2.299 5.496h-2.75l-2.002-4.974h-1.344v4.974H13.92V.182h4.408c.946-.03 1.861.334 2.53 1.003zM35.381.182v2.446h-4.972V5.97h4.533v2.426H30.41v3.97h5.12v2.445H27.92V.182h7.46zm7.9 0l2.195 5.016L47.65.182h2.78L46.75 7.955v6.856h-2.57V7.955L40.524.182h2.757zm20.293 0v2.446h-3.052v12.183h-2.548V2.628h-3.03V.182h8.63zm11.474 0l2.382 8.234v6.395h-2.466v-4.348h-3.802v4.348h-2.468V8.416L71.076.182h3.972zM4.346 8.372l-1.818.002v3.991h1.82c.68.01 1.241-.532 1.254-1.212V9.608c-.03-.678-.578-1.217-1.256-1.236zM73.28 2.628h-.424l-1.456 5.39h3.336l-1.456-5.39zm-54.993-.002h-1.86v4.765h1.86c.55.004 1.005-.43 1.024-.981v-2.8c-.02-.552-.473-.987-1.024-.984zM4.43 2.628H2.528V5.93H4.43c.584.02 1.077-.432 1.108-1.016V3.651c-.026-.587-.52-1.043-1.108-1.023z" transform="translate(-50.000000, -40.000000) translate(50.000000, 40.000000)" />
                </g>
              </g>
            </g>
          </svg>
        </CustomNavLink>
      </div>
      <div className="header__nav-wrapper full-view-height">
        <Menu isOpen={isOpen} mainMenu={mainMenu} />
        <div className="header__socials-info">
          <EmailInfo />
          <SocialsComponent classes="header" />
        </div>
      </div>
      <button
        onClick={toggleMenu}
        className={`header__nav-button ${isOpen ? 'header__nav-button--open' : ''}`}
      >
        <span></span>
      </button>
    </header>
  )
}

Header.propTypes = {
  mainMenu: PropTypes.array
}
