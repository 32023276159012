import React, { useState } from 'react'
import PropTypes from 'prop-types'
import SocialsComponent from './SocialsComponent';

import { useScrollPosition } from '../hooks/useScrollPosition'

import './PageHeroSideElements.scss'

export default function PageHeroSideElements ({copyright, isLightTheme = false}) {

  const [scrollFade, setScrollFade] = useState(0);
  
  useScrollPosition(({currPos}) => {
    const scrollPosition = Math.abs(currPos.y) / 300;
    if( scrollPosition <= 1 || scrollFade <= 1 ) {
      setScrollFade(scrollPosition)
    }
  })

  return (
    <div className={`side-elements ${scrollFade >= 1 ? 'side-elements--disabled' : ''}`} 
      style={{opacity: scrollFade < 1 ? 1 - scrollFade : 0}}
    >
      <div className={`side-elements__copyright ${isLightTheme ? 'side-elements__copyright--light' : ''}`}>
        <p>{copyright}</p>
        <p>{(new Date().getFullYear())}</p>
      </div>
      <SocialsComponent classes="side-elements" isLightTheme={isLightTheme}/>
    </div>
  )
}

PageHeroSideElements.propTypes = {
  copyright: PropTypes.string.isRequired,
  isLightTheme: PropTypes.bool
}