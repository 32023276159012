import {useEffect, useRef} from 'react'

/**
 * useResizeObserver
 * 
 * This hook is using ResizeObserver to watch an element and execute callback function if it's properties change
 * 
 * @param {Func} param.callback - callback function
 * @param {Object} param.element - ref
 */
export default function useResizeObserver({callback, element}) {
  
  const current = element && element.current

  const observer = useRef(null)

  const observe = () => {
    if (current && observer.current) {
      observer.current.observe(element.current);
    }
  };
  
  useEffect(() => {
    // unobserve if we are already watching old element
    if(observer && observer.current && current) {
      observer.current.unobserve(current);
    }

    // initiate ResizeObserver and observe element
    observer.current = new ResizeObserver(callback);
    observe()

    return () => {
      // cleanup
      if (observer && observer.current && element && current) {
        observer.current.unobserve(current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current])
}
