import React from 'react'
import PropTypes from 'prop-types'

import CustomNavLink from './CustomNavLink'

/**
* FooterSubMenuItems component
* @param {Array} param.childItems
*
* @returns {JSX Element}
*/
export default function FooterSubMenuItems({childItems}) {

  const {childItems: {nodes}} = childItems
  
  return (
    <div className="footer-items__sub-items">
      {
        nodes.map((node, index) => {
          return (            
            <div className="footer-items__sub-items-link" key={index}>
              <CustomNavLink path={node.path}>{node.label}</CustomNavLink>
            </div>
          )
        })
      }
    </div>
  )
}

FooterSubMenuItems.propTypes = {
  childItems: PropTypes.object.isRequired
}