import React, { useState, useEffect, useRef } from "react"
import PropTypes from 'prop-types'

import Header from './Header'
import Footer from './Footer'
import SiteLoadingAnimation from '../components/SiteLoadingAnimation'
import Seo from './Seo'

import CustomScroll from './CustomScroll'
import CustomScrollBar from './CustomScrollBar'

import { useCurrentHeight } from '../hooks/useResize'
import useViewportHeight from "../hooks/useViewportHeight"
import { PageTransitionContext } from "../context/pageTransitionContext"

/**
 * Layout component
 * 
 * @param {Node} children
 * @param {Array} mainMenu
 * @param {Array} footerMenu
 * @param {String} copyrightField
 * @param {Boolean} shouldAnimate
 * @param {Boolean} isHomePage
 * @param {String} title
 * 
 * @returns {JSX Element}
 */
const Layout = ({ children, footerMenu, copyrightField, mainMenu, shouldAnimate, isHomePage, title }) => {

  useViewportHeight(); // The useViewportHeight hook sets and updates the --vh custom property for accurate viewport height.

  const isAnimated = typeof window !== 'undefined' && window.sessionStorage.getItem('isAnimated');

  const [footerHeight, setFooterHeight] = useState()
  const [contentHeight, setContentHeight] = useState()
  const [hasMounted, setHasMounted] = useState(false)
  const [pagePosition, setPagePosition] = useState(shouldAnimate ? 100 : 0)
  const [isLoadingAnimation, setIsLoadingAnimation] = useState(isAnimated)

  const [isDragging, setIsDragging] = useState(false)
  const [clickOffsetInBar, setClickOffsetInBar] = useState(0)

  const scrollBar = useRef(null)

  const windowHeight = useCurrentHeight()

  // for smooth scroll
  const ease = 0.08

  /**
   * onMouseDrag
   * 
   * This function is handeling page position when scroll bar is dragged
   * 
   * @param {Event} e
   * @param {Ref} scrollBar
   * @param {Number} clickOffsetInBar - where on scroll bar is clicked
   * @param {Number} windowHeight
   * @param {Number} contentHeight
   * @param {Number} footerHeight
   */
  const onMouseDrag = (e, scrollBar, clickOffsetInBar, windowHeight, contentHeight, footerHeight) => {
    e.preventDefault()
    e.stopPropagation()

    const pageHeight = contentHeight - (windowHeight - footerHeight)
    const mousePositionInScreen = e.clientY - clickOffsetInBar
    const maxScrollBarMove = windowHeight - scrollBar.current.clientHeight

    // moving page scroll to where mouse is, in reverse proportion to how scroll bar moves on scroll
    window.scrollTo({
      top: pageHeight / maxScrollBarMove * mousePositionInScreen,
      left: 0
    });
  }

  function setSessionStorageState() {
    if (isLoadingAnimation === null) {
      window.sessionStorage.setItem('isAnimated', true);
      setIsLoadingAnimation(true)
    } else {
      setIsLoadingAnimation(false)
    }
  }

  function setTimerForPagePosition() {
    let timer = null;
    timer = setTimeout(() => {
      if (pagePosition) {
        setPagePosition(0)
      }
    }, 500)

    return () => {
      clearTimeout(timer)
    }
  }

  useEffect(() => {
    setHasMounted(true)
    setSessionStorageState()
    setTimerForPagePosition()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * On first load we have a small flickering before loading animation starts. 
   * Here we defer page rendering and "prevent" that flicker.
   */
  if (!hasMounted) {
    return null
  }

  return (
    <PageTransitionContext.Provider value={{ setPagePosition }} >
      {isLoadingAnimation && isHomePage && <SiteLoadingAnimation isAnimated={isLoadingAnimation} />}

      {/**
       * This div is used for keeping page height, since footer is not in context(position fixed).
       * This div will have exact height as footer, so smooth scroll can position itself correctly(on top of the page) on page load.
       */}
      <div
        style={{
          height: `${footerHeight}px`,
          width: '100%'
        }}
      ></div>
      <div
        className="global-wrapper"
        style={{
          position: 'relative',
          animation: `${isLoadingAnimation && isHomePage ? 'moveHomePageBody .9s 4s forwards' : 'unset'}`,
          height: `${contentHeight}px`,
          marginTop: `${-footerHeight}px`,
          marginBottom: `${footerHeight}px`
        }}
        onMouseMove={(e) => isDragging && onMouseDrag(e, scrollBar, clickOffsetInBar, windowHeight, contentHeight, footerHeight)}
        onMouseUp={() => setIsDragging(false)}
        onMouseLeave={() => setIsDragging(false)}
        role="presentation"
      >
        <Seo title={title} />
        <Header mainMenu={mainMenu} />
        <CustomScroll marginBottomValue={footerHeight} setContentHeight={setContentHeight} pagePosition={pagePosition} ease={ease}>
          <main>
            {children}
          </main>
        </CustomScroll>
        <Footer footerMenu={footerMenu} copyrightField={copyrightField} footerCallback={setFooterHeight} />
        <CustomScrollBar
          contentHeight={contentHeight}
          footerHeight={footerHeight}
          setClickOffsetInBar={setClickOffsetInBar}
          setIsDragging={setIsDragging}
          ref={scrollBar}
          ease={ease}
        />
      </div>
    </PageTransitionContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  mainMenu: PropTypes.array,
  footerMenu: PropTypes.array,
  copyrightField: PropTypes.string,
  shouldAnimate: PropTypes.bool,
  isHomePage: PropTypes.bool,
  title: PropTypes.string,
}

export default Layout
