import {
  getBgImageType,  //For some reason new plugin returns type = "constrained", so then we need to use this function to revrite it and return fixed or fluid
  getAspectRatio, //Need for BackgroundImage component
  getPlaceholder //This will return Base64 ili tracedSVG
} from 'gbimage-bridge';

/**
 *
 * @param imageData {object} The image data to convert.
 * 
 * Pisem na srpskom da me ceo svet razume, posto njihova funkcija ne radi
 * Napravljen je issue na Githubu tako da verujem da ce srediti to kroz koji dan
 * E sad posto nama ne radi njihova BgImage komponenta koja poziva convertToBgImage funkciju, napravio sam novu funkciju koja ce samo preurediti postojecu datu i vratiti novi shape koji ce BackgroundImage moci da procita
 * https://github.com/timhagn/gatsby-background-image/blob/main/packages/gbimage-bridge/src/index.js - sve funkcije mozete ovde procitati
 * @returns {{}}
 */
export const convertToBgImage = imageData => {
  
  if (imageData && imageData.layout) { //Check does layout prop exist, def val is constrained! NE znam koje su ostale vrednosti to cete vi skontati
    const returnBgObject = {}
    const bgType = getBgImageType(imageData) //moze biti samo fixed ili fluid, ja ga konvertujem u getBgImageType 
    const aspectRatio = getAspectRatio(imageData) // BAckgroundImage-u treba ovaj prop koji novi plugin ne vraca
    const placeholder = getPlaceholder(imageData) // 
    returnBgObject[bgType] = {
      ...imageData.images.fallback,
      ...placeholder,
      aspectRatio,
    }
    return returnBgObject
  }
  return {}
}

/**
   * Wrapper functon that adds timeout handling for fetch api
   * 
   * @param {number} timeout 
   * @param {function} fetchFunc 
   * @param {any} data - optional
   */
 export const fetchWithTimeout = ( timeout, fetchFunc, data=null ) => {
  return Promise.race([
    data ? fetchFunc(data) : fetchFunc(),
    new Promise((_, reject) => setTimeout(() => reject(new Error('timeout')), timeout))
  ]);
}

  /**
   * calcImagePadding
   * 
   * calculates pading of image depending on screen size
   * @param {number} windowWidth 
   * @returns {String} padding value
   */
  export const calcImagePadding = (windowWidth) => {
    if(windowWidth < 600) {
      return '6px'
    }
    else if(windowWidth > 599 && windowWidth < 1024) {
      return '8px'
    }
    else if(windowWidth > 1023) {
      return '12px'
    }
    else {
      return null
    }
  }

  /**
   * applyCssProperties
   * 
   * applys css propertie values to refs
   * @param {Ref} element 
   * @param {String} property 
   * @param {String} value 
   */
  export const applyCssProperties = (element, property, value) =>  {
    if(element.current) element.current.style[property] = value;
  }

  /**
   * getMousePosition
   * 
   * Gets mouse position inside a container
   * @param {Event} e 
   * @param {Ref} container 
   * @returns {Object} mouse coordinates(x, y)
   */
  export const getMousePosition = (e, container) => {
    const containerPosition = container.current.getBoundingClientRect();
    return {x: e.clientX - containerPosition.left, y: e.clientY - containerPosition.top}
  }