import React from 'react'
import PropTypes from 'prop-types'
import FooterSubMenuItems from './FooterSubMenuItems'

/**
* FooterMenuItems component
* @param {Array} param.parentItems
*
* @returns {JSX Element}
*/
export default function FooterMenuItems({parentItems}) {

  const menuItemParent = parentItems.filter(parentItem => parentItem.parentDatabaseId === 0)

  return (
    <div className="footer-items">
      {
        menuItemParent.map((item, index) => {
          return(
            <div className="footer-items__item" key={index}>
              <p className="footer-items__item-title">{item.label}</p>
              <FooterSubMenuItems childItems={item}/>
            </div>
          )
        })
      }
    </div>
  )
}

FooterMenuItems.propTypes = {
  parentItems: PropTypes.array.isRequired
}